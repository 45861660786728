import React from "react";
import { useField } from "formik";
import { useModel, AutoSubform } from "@wq/react";
import { makeProjectForm } from "../hooks.js";

export default function ProjectForm({ name }) {
    const [, { value: project_id }] = useField("project_id"),
        project = useModel("project", project_id || -1),
        parameters = useModel("parameter");
    if (!project || !project.fieldsets || !project.fieldsets.length) {
        return null;
    }
    const subform = makeProjectForm(project, parameters);
    return (
        <AutoSubform
            component={React.Fragment}
            name={name}
            label={`${project.name} Data`}
            subform={subform}
        />
    );
}

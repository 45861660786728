import React from "react";
import { useModel, useConfig, usePluginReducer } from "@wq/react";
import {
    View,
    HorizontalView,
    Typography,
    Button,
    ButtonLink,
} from "@wq/material";
import { useFeatureUrl, useFeatureValues } from "@wq/map";

export default function SitePopup({ feature }) {
    const modelConf = useConfig().pages.site,
        [, { setHighlight }] = usePluginReducer("map"),
        { id, label, downstream_from_id, event_count } = useFeatureValues(
            feature,
            modelConf,
        ),
        sites = useModel("site"),
        upstream = sites.filter((site) => downstream_from_id.includes(site.id)),
        downstream = sites.filter((site) =>
            site.downstream_from_id.includes(id),
        ),
        siteDetail = useFeatureUrl(feature, modelConf, "detail"),
        highlightSite = (site) => {
            setHighlight({
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        id: site.id,
                        properties: site,
                        geometry: site.geometry,
                        popup: "site",
                    },
                ],
            });
        };

    return (
        <>
            <View
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">{label}</Typography>
                <ButtonLink icon="analyst" variant="outlined" to={siteDetail}>
                    View Data ({event_count} events)
                </ButtonLink>
            </View>
            <HorizontalView style={{ alignItems: "flex-start" }}>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    {downstream.length > 0 && (
                        <Typography>Downstream</Typography>
                    )}
                    {downstream.map((site) => (
                        <Button
                            key={site.id}
                            onClick={() => highlightSite(site)}
                        >
                            &lsaquo; {site.label}
                        </Button>
                    ))}
                </View>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                    }}
                >
                    {upstream.length > 0 && <Typography>Upstream</Typography>}
                    {upstream.map((site) => (
                        <Button
                            key={site.id}
                            onClick={() => highlightSite(site)}
                        >
                            {site.label} &rsaquo;
                        </Button>
                    ))}
                </View>
            </HorizontalView>
        </>
    );
}

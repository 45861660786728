import wq from "@wq/app";
import config from "./data/config.js";
import wizard from "@wq/wizard";
import analyst from "@wq/analyst";

import { context } from "./hooks.js";
import * as components from "./components/index.js";
import * as inputs from "./inputs/index.js";
import * as views from "./views/index.js";

const overrides = {
    context,
    components,
    inputs,
    views,
};

wq.use([wizard, analyst, overrides]);

async function init() {
    await wq.init(config);
    await wq.prefetchAll();
    if (config.debug) {
        window.wq = wq;
    }
}

init();

navigator.serviceWorker.register("/service-worker.js");

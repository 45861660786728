import React from "react";

export default function LegendIcon({ name }) {
    const [color, shape] = name.split("-"),
        style =
            shape === "circle"
                ? {
                      border: `3px solid ${color}`,
                      borderRadius: 6,
                      width: 12,
                      height: 12,
                  }
                : {
                      border: `2px solid ${color}`,
                      width: 16,
                      height: 16,
                  };
    return <div style={style} />;
}

import React from "react";
import { usePluginState } from "@wq/react";
import { Select } from "@wq/material";
import { useField } from "formik";

export default function SiteSelect({ choices, ...props }) {
    const [, { value: sampler_id }] = useField("sampler_id"),
        { user } = usePluginState("auth");
    choices = choices
        .map((choice) => ({
            ...choice,
            group: choice.data.samplers_id.includes(sampler_id)
                ? "Assigned"
                : choice.data.is_active
                ? "All"
                : "Inactive",
        }))
        .sort(choiceSort);
    if (!user.is_staff) {
        choices = choices.filter((choice) => choice.group === "Assigned");
    }
    return <Select {...props} choices={choices} />;
}

const priority = {
    Assigned: 3,
    All: 2,
    Inactive: 1,
};

function choiceSort(choice1, choice2) {
    const priority1 = priority[choice1.group],
        priority2 = priority[choice2.group];
    if (priority1 > priority2) {
        return -1;
    } else if (priority1 < priority2) {
        return 1;
    } else {
        return 0;
    }
}

import React, { useEffect } from "react";
import { Select } from "@wq/material";
import { usePluginState } from "@wq/react";

export default function SamplerSelect({ choices, ...props }) {
    const { user } = usePluginState("auth");
    choices = choices
        .map((choice) => ({
            ...choice,
            group: choice.data.users_id.includes(user.username)
                ? "Assigned"
                : "All",
        }))
        .sort(choiceSort);
    if (!user.is_staff) {
        choices = choices.filter((choice) => choice.group === "Assigned");
    }
    return <Select {...props} choices={choices} />;
}

function choiceSort(choice1, choice2) {
    if (choice1.group === "Assigned" && choice2.group === "All") {
        return -1;
    } else if (choice1.group === "All" && choice2.group === "Assigned") {
        return 1;
    } else {
        return 0;
    }
}

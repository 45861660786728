import React, { useEffect } from "react";
import { Input, Toggle } from "@wq/material";
import { useField } from "formik";

export default function QualifierInput(props) {
    const valName = props.name + "_val",
        qualName = props.name + "_qual",
        [, { value }, { setValue }] = useField(props.name),
        [, { value: valValue }, { setValue: setValValue }] = useField(valName),
        [, { value: qualValue }, { setValue: setQualValue }] =
            useField(qualName);

    useEffect(() => {
        if (valValue === undefined && qualValue === undefined) {
            const parts = (value || "").toString().split(" ");
            if (parts.length <= 2 && !isNaN(+parts[parts.length - 1])) {
                setQualValue(parts.length == 2 ? parts[0] : "");
                setValValue(+parts[parts.length - 1]);
            }
        } else {
            const nextValue = qualValue ? qualValue + " " + valValue : valValue;
            if (value !== nextValue) {
                setValue(nextValue);
            }
        }
    }, [value, valValue, qualValue]);

    if (
        valValue === undefined ||
        qualValue === undefined ||
        (qualValue && !qualChoices.some((choice) => choice.name === qualValue))
    ) {
        return <Input {...props} type="string" />;
    }
    return (
        <div style={{ display: "flex", flex: 1 }}>
            <div style={{ paddingRight: 8, paddingTop: 5 }}>
                <Toggle name={qualName} choices={qualChoices} />
            </div>
            <Input {...props} name={valName} />
        </div>
    );
}
const qualChoices = [
    { name: "<", label: "<" },
    { name: ">", label: ">" },
];

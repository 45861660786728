import React from "react";
import { useModel } from "@wq/react";
import { PropertyTable as DefaultPropertyTable } from "@wq/react";
import { makeProjectForm } from "../hooks.js";

export default function PropertyTable({ form, values }) {
    if (values.form && values.form.project_form) {
        return <ResultPropertyTable form={form} values={values} />;
    } else {
        return <DefaultPropertyTable form={form} values={values} />;
    }
}

function ResultPropertyTable({ form: defaultForm, values }) {
    const project = useModel("project", values.project_id),
        parameters = useModel("parameter");
    values = { ...values, ...values.form.project_form };
    const form = [];
    for (const field of defaultForm) {
        if (field.name == "form") {
            form.push(...makeProjectForm(project, parameters));
        } else {
            form.push(field);
        }
    }
    return <DefaultPropertyTable form={form} values={values} />;
}

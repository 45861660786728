import React from "react";

export default function SiteTitle() {
    return (
        <>
            <span
                style={{
                    color: "#7ee8ff",
                    fontWeight: "bold",
                    fontFamily: "'Poiret One', cursive",
                    fontSize: "1.4em",
                }}
            >
                river.watch
            </span>
            <br />
            <span
                style={{
                    color: "white",
                    fontWeight: "normal",
                    fontSize: "0.8em",
                }}
            >
                citizen science & water quality data
            </span>
        </>
    );
}
